<template>
  <div class="text-center">
    <v-dialog v-model="dialogInterno" width="600" persistent>
      <v-card>
        <v-card-title class="lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ text }}
          <br />
          <div class="mt-8">
            <v-form ref="form" v-model="valid">
              <v-text-field
                label="Telefone celular com DDD"
                :rules="rules.phone"
                v-mask="['(##) #####-####']"
                v-model="phone"
                required
                validate-on-blur
              ></v-text-field>
              <div class="actions text-center mt-6">
                <v-btn
                  color="success"
                  :loading="loading"
                  @click="changePhone()"
                >
                  Atualizar
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { authComputed } from '@state/helpers'
export default {
  props: {
    title: {
      type: String,
      default: 'Aviso',
    },
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      userData: 'changePassword/userData',
    }),
    text() {
      return `Cliente ${this.currentUser.firstName}, para manter nossa comunicação efetiva estamos solicitando a atualização do seu telefone de contato para nossa base de dados.`
    },
  },
  data() {
    return {
      dialogInterno: true,
      loading: false,
      phone: '',
      valid: false,
      rules: {
        phone: [
          (v) =>
            v.length > 9 || 'O número de telefone precisa ter mais 10 digitos.',
          (v) => !!v || 'Por favor preencha o telefone.',
        ],
      },
    }
  },
  methods: {
    changePhone() {
      let phoneNoMask = this.$options.filters.unmaskText(this.phone)
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$store
          .dispatch('informationToUpdate/phoneChange', {
            phone: phoneNoMask,
          })
          .then(() => {
            this.loading = false
            this.dialogInterno = false
          })
      }
    },
  },
  mounted() {
    this.phone = this.currentUser.phone
    this.dialogInterno = !this.currentUser.wasUpdated
  },
}
</script>
